import { Injectable } from '@angular/core';
import { SettingsControllerService } from './settings-controller.service';
import { FilesystemDirectory, Plugins } from '@capacitor/core';
import { LoggerService } from '../services/logger.service';

import { Capacitor } from '@capacitor/core';

const { Filesystem } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class AudioController {

  private basePath: string;
  private basePathIsInitialized: boolean = false;

  constructor(
    public settingsCtrl: SettingsControllerService,
    private logger: LoggerService,
  ) {
    this.logger.entry("AudioController.constructor()");
  }

  // Getter method for the audio base path 
  public getBasePath() {
    return this.basePath;
  }

  // Getter method for the audio path.  If path hasn't been setup yet, it sets
  // it up
  public async getAudioPath() {
    this.logger.entry("AudioController.getAudioPath()");

    return new Promise<string>(async resolve => {
      // if the base path hasn't been generated yet, do it now
      if (!this.basePathIsInitialized) {
        this.logger.debug("AudioController.getAudioPath: generating base path....")
        await this.generateBasePath(); 
          this.logger.info('getAudioPath: generated base path: ', this.basePath + '/' + this.settingsCtrl.language + '-');
          this.logger.exit("AudioController.getAudioPath()");
          resolve(this.basePath + '/' + this.settingsCtrl.language + '-');
        // else we already have the base path, we can return right away
      } else {
        this.logger.info('getAudioPath: returning: ', this.basePath + '/' + this.settingsCtrl.language + '-');
        resolve(this.basePath + '/' + this.settingsCtrl.language + '-');
      }
    });
  }

  private generateBasePath() {
    this.logger.entry("AudioController.generateBasePath()");
    this.logger.debug('AudioController.generateBasePath: calling getURI on', FilesystemDirectory.Data);
    return new Promise(resolve => {
      Filesystem.getUri({
        directory: FilesystemDirectory.Data,
        path: '',
      }).then((uri) => {
        this.logger.debug(`👺 AudioController.generateBasePath: getURI: returns with ${JSON.stringify(uri, undefined, 2)}. Now calling convertFileSrc`);
        this.basePath = Capacitor.convertFileSrc(uri.uri);
        this.logger.debug('😁 AudioController.generateBasePath: aC: audioPath set to ', this.basePath);

        // Set a flag indicating that we've initialized the basePath
        this.basePathIsInitialized = true;
        resolve();
      }).catch(e => {
        this.logger.error('AudioController.generateBasePath: ----->', e, "\nSetting the basePath to /Data");
        this.basePath = '/Data';

        // Even though this is an error state, we still set a flag indicating that 
        // we've initialized the basePath
        this.basePathIsInitialized = true;
        resolve();
      });
    });
  }
}


